import React, { FC, useEffect } from 'react'
import cx from 'classnames'
import styles from './Financing.module.scss'
import { Heading } from 'components/basic/Heading'
import { Img } from 'components/basic/Img'
import { Button } from 'components/WebEv/Buttons'
import { observer } from 'mobx-react'
import { useRootStore } from 'components/_hooks/useRootStore'
import { PriceManager } from 'prices'
import { PropsWithInjectedRootStore } from 'constants/types'
import { AffirmLogo } from 'components/_const_assets/affirmLogo'
import { KlarnaLogo } from 'components/_const_assets/klarnaLogo'

export interface FinancingProps {
	monthlyPriceFormatted: string
	maxMonthsAtZeroInterest: number
	isPrimary?: boolean
	ctaHref?: string
	anchorLink?: boolean
}

export interface FinancingInfoProps extends PropsWithInjectedRootStore {
	price?: number
	priceFormatted?: string
	className?: string
	noLogo?: boolean
	customLogoClass?: string
	color?: 'black' | 'white'
}

interface FinancingButtonProps {
	text: string
	price?: string
	className?: string
	noArrow?: boolean
}

export const AffirmButton: FC<FinancingButtonProps> = (props) => {
	return (
		<a
			className={cx(styles.animated_arrow, 'affirm-site-modal', styles.prequalify, props.className)}
			data-provider="Affirm"
			data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
			role="button"
			tabIndex={0}
			aria-label="Pre qualify button for affirm"
		>
			{props.noArrow ? (
				props.text
			) : (
				<>
					<span className={cx(styles.the_arrow, styles.pos_left)}>
						<span className={styles.shaft} />
					</span>
					<span className={styles.main}>
						<span className={styles.text}>{props.text}</span>
						<span className={cx(styles.the_arrow, styles.pos_right)}>
							<span className={styles.shaft} />
						</span>
					</span>
				</>
			)}
		</a>
	)
}

export const FinancingInfo: FC<FinancingInfoProps> = observer((props) => {
	const { settingsStore, priceStore } = useRootStore()
	const region = settingsStore.currentRegion
	const currency = priceStore.currency
	const price = props.price ?? priceStore.pod4PriceNumber
	const priceFormatted = props.priceFormatted ?? priceStore.pod4MonthlyPriceFormatted

	if (region === 'ca' || region === 'us' || region === '') {
		return (
			<a
				className={cx(styles.affirm_link, props.className, 'affirm-site-modal')}
				data-provider="Affirm"
				data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
				role="button"
				tabIndex={0}
				aria-label="Pre qualify button for affirm"
			>
				{region === 'ca' ? (
					<span>finance monthly with</span>
				) : (
					<span>or as low as {priceFormatted ?? PriceManager.formatPriceToCurrencyNoDecimal(PriceManager.getFinancingAmount(price / 100, region), currency)}/mo with </span>
				)}
				<AffirmLogo
					className={props.customLogoClass || 'affirmTransform'}
					textColor={props.color}
				/>
			</a>
		)
	} else if (region === 'uk' || region === 'eu') {
		return (
			<span className={cx(props.className)}>
				or pay in 3 with{' '}
				<KlarnaLogo
					color={props.color || 'black'}
					className={props.customLogoClass}
					key={'fin'}
				/>
			</span>
		)
	} else {
		return <></>
	}
})

export const Financing: FC<FinancingProps> = (props) => {
	let financingProviderLogo = null
	let financingProviderButton = null
	const financingProvider = 'affirm'
	switch (financingProvider) {
		case 'affirm':
			financingProviderLogo = (
				<Img
					alt="Affirm"
					src="https://eightsleep.imgix.net/Affirm_logo_58388c1c-78ec-47bb-b8db-5d30f2307e21.svg?v=1636470441"
					className={styles.affirm_logo}
				/>
			)
			financingProviderButton = <AffirmButton text="Pre-qualify now" />
			break
		default:
	}

	useEffect(() => {
		if ((window as any).affirm?.ui?.refresh !== undefined) {
			;(window as any).affirm.ui.refresh()
		}
	}, [])

	return (
		<div className={cx(styles.financing_container, 'max-1440')}>
			<div className={styles.financing_content}>
				<Heading
					type={'h3'}
					title={
						<>
							Get sleep fit for as low as {props.monthlyPriceFormatted}/mo with {financingProviderLogo}
						</>
					}
				/>
				<div className={styles.financing_items}>
					<div>
						<span>as low as</span>
						<div>
							<sup>{props.monthlyPriceFormatted.substring(0, 1)}</sup>
							{props.monthlyPriceFormatted.substring(1)}
						</div>
						<span>per month</span>
					</div>
					<div>
						<span>for up to</span>
						<div>{props.maxMonthsAtZeroInterest}</div>
						<span>months</span>
					</div>
					<div>
						<span>starting at</span>
						<div>
							0<sup>%</sup>
						</div>
						<span>
							APR<sup>1</sup>
						</span>
					</div>
				</div>
				{props.anchorLink ? (
					<Button.Secondary
						href={props.ctaHref}
						id={'financing_shop_now'}
						className={styles.cta_button}
					>
						Shop now
					</Button.Secondary>
				) : (
					<Button.Secondary
						href={props.ctaHref ?? '/'}
						id={'financing_shop_now'}
						className={styles.cta_button}
					>
						Shop now
					</Button.Secondary>
				)}

				<div>{financingProviderButton}</div>
			</div>
		</div>
	)
}
